<template>
  <div id="login-section" class="no-result card main" :style="border === true ? 'border:none;box-shadow:none;padding:0px;margin:0px;' : ''"> 
    <span class="link" @click="this.$emit('login')">가입 / 로그인</span><br><br>
    <span style="font-size: 15px;"><label class="link" @click="this.$emit('login')">가입/로그인</label>을 하셔야 서비스를 이용하실 수 있습니다</span>
  </div>
</template>

<script>
export default {
  props: {
    border:Boolean
  },
}
</script>
<style scoped>
  #login-section {
    font-size:20px;
    font-weight:bold;
  }
</style>
