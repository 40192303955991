<template>
  <div :key="mypageKey" >
    <div class="div_title">
      <context :context=cate />
      <policies/>
    </div>
    <div v-if="this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true" > 
      <span v-if="contractList.length !== 0">
        <div class="card main">
          <table class="mypage-table" v-for="(user, index) in contractList" :key="index" style="width:100%">
            <tr>
              <td colspan="4" style="background-color: aliceblue;" >
                {{ user.NAME }}
              </td>
            </tr>
            <tr>
              <td>대표</td>
              <td>{{ user.CEO }}</td>
              <td>사업자번호</td>
              <td>{{ user.SERVICE }}</td>
            </tr>
            <tr>
              <td>연락처</td>
              <td>{{ user.PHONE }}</td>
              <td>가입일</td>
              <td>{{ this.api.getDateByFormat(user.REG_DATE, 'YYYY.MM.DD') }}</td>
            </tr>
          </table>
          <table class="mypage-table" style="width:100%;">
            <tr>
              <td colspan="4">
                <br>
                <Button label="업체 페이지 이동" @click="click({type:'contract'})" raised size="small"/>
              </td>
            </tr>
          </table>
        </div>
      </span>

      <div class="div_title">
        <span style="color: #ff7f50;">알림</span>
        <label style="font-weight:normal;cursor:pointer;" @click="click({type:'DELETE_NOTICE_BY_ID'})" v-if="msgList.length !== 0">전체삭제</label>
      </div>
      <div class="card main">
        <p v-if="msgList.length === 0" class="point no-result"><span >메시지가 없습니다.</span></p>
        <p v-else class="point" v-for="(msg, index) in msgList" :key="index">
          <span @click="click({type:'move', seq:msg.SEQ, query:msg.QUERY})" >[ {{this.api.getDateByFormat(msg.REG_DATE, 'YYYY.MM.DD')}} ] &nbsp; <label :style="msg.USE_YN === false ? 'font-weight:bold;color:blue;cursor:pointer;' : 'font-weight:normal;cursor:pointer;'">{{msg.TITLE}}</label></span>
        </p>
        <Divider />
        <div style="text-align: center;" v-if="msgList.length !== 0">
          <label @click="msgPaging('prev')" class="link">
            이전
          </label>
          <span style="margin:20px">{{msgPage}}</span>
          <label @click="msgPaging('next')" class="link">
            다음
          </label>
        </div>
      </div>
      
      <div class="div_title"><span style="color: #ff7f50;">포인트 : <span style="color:#ffa07a">{{Number(this.point).toLocaleString()}}&nbsp;point</span></span> </div>
      <div class="card main">
        <p>포인트 적립 내역 </p>
        <p v-if="pointHistoryList.length === 0" class="point no-result"><span >적립내역이 없습니다.</span></p>
        <p v-else class="point" v-for="(item, index) in pointHistoryList" :key="index">
          <span>[ {{this.api.getDateByFormat(item.REG_DATE, 'YYYY.MM.DD')}} ] &nbsp; {{item.TYPE}} <label class="point-font">{{this.getPoint(item)}}</label></span>
        </p>
        <Divider />
        <div style="text-align: center; " v-if="pointHistoryList.length !== 0">
          <label @click="paging('prev')" class="link">
            이전
          </label>
          <span style="margin:20px">{{page}}</span>
          <label @click="paging('next')" class="link">
            다음
          </label>
        </div>
      </div>
      <!-- <div class="card main">
        <p>포인트는 아래의 방법으로 획득 가능합니다.</p>
        <p><span class="point">1. &nbsp;매일 1회, 로그인 시 <label class="point-font">+1</label></span></p>
        <p><span class="point">2. &nbsp;구매대행/배송대행 거래완료 시 건당 <label class="point-font">+30</label></span></p>
        <p><span class="point">3. &nbsp;카톡에서 상담원을 통해 구매하신 경우에는 거래완료가 되어도 <label class="point-font">+30</label> 제공되지 않습니다.</span></p>
      </div>
      <div class="div_title"><span style="color: #ff7f50;">게임</span> </div>
      <div class="navbar">
        <span id="menu0" style="width:20%" @click='menu_click(0)' class="active" >로또</span>
        <span id="menu1" style="width:20%" @click='menu_click(1)'>테트리스</span> 
        <span id="menu2" style="width:20%" @click='menu_click(2)'>야구</span> 
        <span id="menu3" style="width:20%" @click='menu_click(3)'>수학</span> 
        <span id="menu4" style="width:20%" @click='menu_click(4)'>영어</span> 
      </div>
      <lotto id="sub_menu0" :point="this.point" @reload="reload" :key="lottoKey"/>
      <div id="sub_menu1">
        <p>작업 중...</p>
      </div>
      <div id="sub_menu2">
        <p>작업 중...</p>
      </div>
      <div id="sub_menu3">
        <p>작업 중...</p>
      </div>
      <div id="sub_menu4">
        <p>작업 중...</p>
      </div> -->
    </div>
    <div v-else> 
      <login @login="login" /> 
    </div>
  </div>
  <div class="ad-container">
    <Adsense
      data-ad-client="ca-pub-6699354447815429" 
      data-ad-slot="8230887469"
      data-ad-format="horizontal"
      data-full-width-responsive="true">
    </Adsense>
  </div>
  <!-- <span ref="countdown" v-if="this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true">
    <Countdown :deadline=this.getNextSaturday() style="margin-top:30px;" mainColor="white"  secondFlipColor="white" />
  </span> -->
  <div v-if="this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true"  class="link margin10" style="float:right;" @click="click({type:'quit'})">탈퇴하기</div>
</template>

<script>

// import { useMeta } from 'vue-meta'
// import lotto from '@/components/mypage/Lotto.vue'
// import { Countdown } from 'vue3-flip-countdown'
import router from '@/router'
import { ref, inject } from 'vue'
import context from '@/components/common/Context.vue'
import policies from '@/components/common/policies.vue'
import login from '@/components/common/Login.vue'

export default {
  emits: ["setCookies", "setMenu", "login", "logout"],
  setup() {
    const setMeta = inject('setMeta')
    setMeta({
      title: 'GEEK9 > 마이페이지 > 내정보',
      keywords: '탈퇴,직구,geek9,사용자,내정보,마이페이지,정보,개인정보,geek9,직구,해외직구,소비자',
      description: 'GEKK9 사용자의 정보 및 회원탈퇴',
      'og:title': 'GEEK9 > 마이페이지 > 내정보',
      'og:description': 'GEKK9 사용자의 정보 및 회원탈퇴',
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': 'https://geek9.kr/myinfo',
      'canonical': 'https://geek9.kr/myinfo'
    })
    // useMeta( {
    //   title: 'GEEK9 > 마이페이지 > 내정보',
    //   link: [{rel: "canonical", href: 'https://geek9.kr/myinfo'}],
    //   meta: [
    //     { vmid: 'charset_point', charset: 'utf-8' },
    //     { vmid: 'http-equiv_point', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1' },
    //     { vmid: 'company_point', name: 'company', itemprop: 'name', content: 'GEEK9' },
    //     { vmid: 'keywords_point', name: 'keywords', content:'탈퇴,직구,geek9,사용자,내정보,마이페이지,정보,개인정보,geek9,직구,해외직구,소비자' },
    //     { vmid: 'viewport_point', name: 'viewport', content: 'width=device-width' },
    //     { vmid: 'description_point', name: 'description', content: 'GEKK9 사용자의 정보 및 회원탈퇴' },
    //     { vmid: 'theme-color_point', name: 'theme-color', content: '#ffa07a'},
    //     { vmid: 'og:site_name_point', property: 'og:site_name', content: 'GEEK9'},
    //     { vmid: 'og:description_point', property: 'og:description', content: 'GEKK9 사용자의 정보 및 회원탈퇴'},
    //     { vmid: 'og:title_point', property: 'og:title', content: 'GEEK9 > 마이페이지 > 내정보'},
    //     { vmid: 'og:image_point', property: 'og:image', content: 'https://geek9.kr/img/logo_big.e0186dc8.jpg'},
    //     { vmid: 'og:url_point', property: 'og:url', content: 'https://geek9.kr/myinfo'}
    //   ]
    // })
    const isShow = ref(false)
    let imgList = ref([])
    let desc = ref()
    let country = ref()
    let url = ref()
    function showModal (param) {
      const type = param.type
      if(type === 'imgList') {
        this.currentModalSlide = 0
        desc.value = ''
        country.value = ''
        url.value = ''
        imgList.value = param.imgList
      } else if(type === 'desc'){
        desc.value = param.desc 
        country.value = param.country
        url.value = param.url
        imgList.value = []
      }
      isShow.value = true
    }
    function closeModal () {
      isShow.value = false
    }
    const cate = ref([
      { label: '마이페이지'},
      { label: '내정보', route: '/myinfo' }
    ])
    return {
      isShow, cate, closeModal, showModal, imgList, desc, country, url, 
      // showRegistModal, closeRegistModal, isRegist, loginId
    }
  },
  components: {
    // lotto, Countdown, 
    policies, 
    context,
    login
  },
  unmounted() {
    this.emitter.off('setValue', this.setValue)
    this.emitter.off('myinfoReload', this.reload)
  },
  mounted() {
    this.emitter.on('setValue', this.setValue)
    this.emitter.on('myinfoReload', this.reload)
    this.$emit("setMenu", {page:'myinfo'})
    if( (this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true)  ) {
      // this.search({type:'SELECT_ORDER_LIKE', page:1})
      this.api.setPage('myinfo')
      this.search({type:"SELECT_NOTICE4", page:1})
      .then( async (res) => {
        this.msgList = res.data
      })
      .finally(()=> {
        this.emitter.emit('dialog', {flag:false, title:'loading'})
      })
      console.log(this.api.getCookies('page'))
      if(this.api.getCookies('id') === '1513898286') {
        this.api.axiosGql('SELECT_CONSUMER', {type:'SELECT_CONSUMER', mail:this.api.getCookies('mail'), userType:1})
        .then( async (res) => {
          this.contractList = res.data
          this.contractList
          for(let i=0; i<this.contractList.length; i++) {
            const user = this.contractList[i] 
            await this.api.axiosGql('SELECT_COMPANY', {type:'SELECT_COMPANY', num:user.SERVICE})
            .then( async (res2) => {
              const json = res2.data[0]
              this.contractList[i].CEO = json.CEO
              this.contractList[i].NAME = json.NAME
              this.contractList[i].PHONE = json.PHONE
            })
          }
        })
      }

      // const queryIdx = this.$route.query.idx
      // const paramIdx = this.$route.params.idx
      // let idx = 0
      // if(queryIdx !== undefined) {
      //   idx = Number(queryIdx)
      // } else if(paramIdx !== undefined) {
      //   idx = Number(paramIdx)
      // }
      this.reload('dashboard')
      // this.menu_click(idx)
    }
    window.scrollTo(0, 0)
  },
  data() { 
    return { 
      selectedCountry:'',
      currentSlide: [],
      currentModalSlide: 0,
      mypageKey: 0,
      point: 0,
      msg:1,
      msgPage:1,
      page:1,
      likePage:1,
      contractList: [],
      msgList: Array(),
      pointHistoryList: Array(),
      line: '',
      selectedMenu:0,
      resultJson:[],
      totalWonPrice:0,
      totalForeignPrice:0.0,
      totalForeignDeliveryPrice:0.0
    }
  },
  methods: {
    login() {
      this.$emit('login')
    },
    checkbox() {
      // mypage-wrapper
      let selectedWrapperId = ''
      if(this.selectedMenu === 0) selectedWrapperId = '#mypage-order'
      else selectedWrapperId = '#cart-wrapper'
      let checkedList = document.querySelectorAll(selectedWrapperId + ' input[type="checkbox"]:checked')
      this.totalWonPrice = 0
      this.totalForeignPrice = 0
      this.totalForeignDeliveryPrice = 0.0

      for(let i=0; i<checkedList.length; i++) {
        const selectedIdx = Number(checkedList[i].dataset.checkbox)
        const order = this.resultJson[selectedIdx]
        
        this.selectedCountry = order.COUNTRY
        const wonPrice = order.SELL_PRICE
        this.totalWonPrice += wonPrice
        const foreignDeliveryPrice = Number(order.FOREIGN_DELIVERY_PRICE)
        const originPrice = Number(order.ORIGIN_PRICE)
        const sellPrice = (originPrice - foreignDeliveryPrice).toFixed(2)
        this.totalForeignDeliveryPrice += foreignDeliveryPrice
        this.totalForeignPrice += Number(sellPrice)
      }
      checkedList = document.querySelectorAll(selectedWrapperId + ' input[type="checkbox"]:checked')

      if(checkedList.length === 0) {
        this.selectedCountry = ''
      }
    },
    slideTo(param) {
      const val = param.val
      const idx = param.idx
      this.currentSlide[idx] = val
    },

    async click(param) {
      let type = param.type
      
      if(type === 'contract') router.push({name:type})
      if(type === 'move') {
        const query = param.query
        if(query.indexOf('mail:') === -1) router.push({name:query})
        else alert('메일을 확인해주세요!')
        await this.api.axiosGql('UPDATE_NOTICE', {type:'UPDATE_NOTICE', seq:param.seq, useYn:true})
        .then(async () => {})
        .finally(()=>{
          this.emitter.emit("selectNotice")
        })
      } else if(type === 'menu') {
        this.selectedMenu = param.value
        for(var i=0; i<2; i++) {
          let menu = document.getElementById('mypageMenu' + i)
          if(i === this.selectedMenu) menu.setAttribute("class", "active")
          else menu.setAttribute("class", "")
        }
        let p
        if(this.selectedMenu === 0) {
          type = 'SELECT_ORDER_LIKE'
          p = this.likePage
          this.clearCartInfo()
        } else {
          type = 'SELECT_ORDER_CART'
          p = -1
        }
        this.search({type:type, page:p})
      } else if(type === 'selectAll') {
        let selectedWrapperId = ''
        if(this.selectedMenu === 0) selectedWrapperId = '#mypage-order'
        else selectedWrapperId = '#cart-wrapper'
        let checkedList = document.querySelectorAll(selectedWrapperId + ' input[type="checkbox"]:checked')
        let list = document.querySelectorAll(selectedWrapperId + ' input[type="checkbox"]')
        if(checkedList.length === 0) {

          if(this.selectedMenu === 0) {
            for(let i=0; i<list.length; i++) {
              list[i].checked = true
            }
          } else {
            for(let i=0; i<list.length; i++) {
              list[i].checked = true
              const selectedIdx = Number(i)
              const order = this.resultJson[selectedIdx]
              this.selectedCountry = order.COUNTRY
              const wonPrice = order.SELL_PRICE
              this.totalWonPrice += wonPrice
              const foreignDeliveryPrice = Number(order.FOREIGN_DELIVERY_PRICE)
              const originPrice = Number(order.ORIGIN_PRICE)
              const sellPrice = (originPrice - foreignDeliveryPrice).toFixed(2)
              this.totalForeignDeliveryPrice += foreignDeliveryPrice
              this.totalForeignPrice += Number(sellPrice)
            }
          }
        } else {
          for(let i=0; i<list.length; i++) {
            list[i].checked = false
          }
          this.clearCartInfo()
        }
      } else if(type === 'left') {
        let imgList = param.imgList
        const idx = param.idx
        if(this.currentSlide[idx] === 0) this.currentSlide[idx] = imgList.length - 1
        else this.currentSlide[idx]--
      } else if(type === 'right') {
        let imgList = param.imgList
        const idx = param.idx
        if(this.currentSlide[idx] === imgList.length - 1) this.currentSlide[idx] = 0
        else this.currentSlide[idx]++
      } else if(type === 'leftModal') {
        let imgList = param.imgList
        if(this.currentModalSlide === 0) this.currentModalSlide = imgList.length - 1
        else this.currentModalSlide--
      } else if(type === 'rightModal') {
        let imgList = param.imgList
        if(this.currentModalSlide === imgList.length - 1) this.currentModalSlide = 0
        else this.currentModalSlide++
      } else if(type === 'DELETE_ORDER_LIKE' || type === 'DELETE_ORDER_CART') {
        const delIdxList = param.idxList
        let checkedList = []
        if(delIdxList === undefined) {
          let selectedWrapperId = ''
          if(this.selectedMenu === 0) selectedWrapperId = '#mypage-order'
          else selectedWrapperId = '#cart-wrapper'
          let tmpList = document.querySelectorAll(selectedWrapperId + ' input[type="checkbox"]:checked')

          tmpList.forEach((item) => {
            checkedList.push(Number(item.value))
          })
        } else checkedList = delIdxList
        
        if(checkedList.length === 0) {
          alert('삭제할 상품을 선택해주세요')
        } else {
          if(confirm('선택한 상품 ' + checkedList.length +'건을 삭제할까요?') === false) return
          else {
            this.emitter.emit('dialog', {flag:true, title:'loading'})
            await this.api.axiosGql(type, {type:type, list:checkedList})
            .then( async (res) => {
              if(res.status === 200) {
                if(type === 'DELETE_ORDER_LIKE') {
                  if((this.resultJson.length === checkedList.length) && this.likePage > 1) this.likePage--
                  this.search({type:'SELECT_ORDER_LIKE', page:this.likePage})
                } else {
                  this.search({type:'SELECT_ORDER_CART', page:-1})
                  this.clearCartInfo()
                }
                alert(checkedList.length + '건을 삭제했습니다')
              } else alert(res.data.error)
            }).catch( (err) => {
              console.log(err)
              alert('다시 시도해주세요')
            }).finally( () => {
              this.emitter.emit('dialog', {flag:false, title:'loading'})
            })
          }
        }
      } else if(type === 'cart') {
        let checkedList = document.querySelectorAll('#cart-wrapper input[type="checkbox"]:checked')
        if(checkedList.length === 0) {
          alert('카트에 추가할 상품을 선택해주세요')
        }
      } else if(type === 'desc') {
        const country = param.country
        param.url = this.api.getGoogleTranslateUrl({country:country, desc:param.desc})
        this.showModal(param)
      } else if(type === 'imgList') {
        const imgList = param.imgList
        this.showModal({type:type, imgList:imgList.split(',')})
      } else if(type === 'slide') {
        this.currentModalSlide = param.idx
      } else if(type === 'regOne') {
        const json = param.item
        let list = []
        list.push(json)
        router.push({ name: "order", params:  {'list':JSON.stringify(list)}})
      } else if(type === 'reg') {
        if(this.$refs.mypage_chk_agree.checked) {
          
          let checkedList = document.querySelectorAll('#cart-wrapper input[type="checkbox"]:checked')

          if(checkedList.length === 0) alert('주문할 상품을 선택해주세요')
          else {
            if(confirm('주문하시겠습니까?') === false) return
            let initCountry = ''
            let list = []
            let isValid = true
            for(let i=0; i<checkedList.length; i++) {
              const selectedIdx = Number(checkedList[i].dataset.checkbox)
              const country = this.resultJson[selectedIdx].COUNTRY
              if(initCountry === '') {
                initCountry = country
                const order = this.resultJson[selectedIdx]
                const json  = { URL: order.URL, NAME: order.NAME, ORIGIN_PRICE:order.ORIGIN_PRICE, COUNTRY:order.COUNTRY, IMG_URL:order.IMG_URL, AMOUNT:1, ORDER_NUMBER:(i+1), WON_PRICE:order.SELL_PRICE }
                list.push(json)
                if(checkedList.length === 1) break
              } else {
                if(initCountry !== country) {
                  alert('합배송은 동일한 국가의 상품끼리만 가능합니다.')
                  isValid = false
                  break
                } else {
                  const order = this.resultJson[selectedIdx]
                  const json  = { 
                    URL: order.URL, 
                    NAME: order.NAME, 
                    ORIGIN_PRICE:order.ORIGIN_PRICE, 
                    country:order.COUNTRY, 
                    IMG_URL:order.IMG_URL, 
                    AMOUNT:1, 
                    ORDER_NUMBER:(i+1), 
                    WON_PRICE:order.SELL_PRICE 
                  }
                  list.push(json)
                }
              }
            }
            if(isValid) router.push({ name: "order", params:  {'list':JSON.stringify(list)}})
          }
        } else {
          const el = document.getElementById('mypage_agree_section')
          el.setAttribute('class', 'blink')
          alert("주의사항에 동의해주셔야 등록이 가능합니다.")
        }
      } else if(type === 'INSERT_ORDER_CART') {
        let item = param.item
        
        if(item === undefined) {
          let checkedList = []
          const checkedinput = document.querySelectorAll('#mypage-order input[type="checkbox"]:checked')
          checkedinput.forEach((item) => {
            checkedList.push(Number(item.value))
          })
          if(checkedList.length === 0) alert('상품을 선택해주세요')
          else {
            for(let i=0; i<checkedList.length; i++) {
              item = this.resultJson[i]
              await this.insert({type:type, item:item})
            }
          }
        } else this.insert({type:type, item:item})

      } else if(type === 'quit') {
        if(confirm("탈퇴하시겠습니까?")) {
          window.Kakao.API.request({
            url: '/v1/user/unlink',
            success: (res) => {
              const id = this.api.getCookies('id')
              if((res === undefined || String(res.id) !== id)) alert('다시 시도해주세요') 
              else {
                alert('탈퇴되었습니다')
                this.logout()
              }
            },
            fail: (err) => {
              console.log(err)
              this.logout()
            }
          })
        }
      } else if(type === 'DELETE_NOTICE_BY_ID') {
        if(confirm("메시지를 모두 삭제할까요?")) {
          await this.api.axiosGql(type, {type:type, id:this.api.getCookies('id')} )
          .then( async (result) => {
            if(result.status === 200) {
              alert('삭제되었습니다')
              await this.search({type:"SELECT_NOTICE4", page:1})
              .then( async (res) => {
                this.msgList = res.data
              })
              .finally(()=> {
                this.emitter.emit('dialog', {flag:false, title:'loading'})
              })
            }
          })
          .catch( (err) => {
            console.log(err)
          })
        }
      }
    },
    async insert(param) {
      const type = param.type
      const item = param.item
      let p = {
        id:this.api.getCookies('id'), 
        type:type,
        country:item.COUNTRY, 
        url:item.URL, 
        name:item.NAME, 
        imgUrl:item.IMG_URL, 
        brand:item.BRAND, 
        foreignDeliveryPrice:item.FOREIGN_DELIVERY_PRICE, 
        originPrice:item.ORIGIN_PRICE, 
        sellPrice:item.SELL_PRICE, 
        imgList:item.IMG_LIST, 
        size:item.SIZE, 
        description:item.DESC
      }
      await this.api.axiosGql(type, p)
      .then( async (res) => {
        if(res.status === 200) {
          // alert('카트에 담았습니다.')
          this.$notify('[' + item.IDX + ']를 카트에 담았습니다.')
        } else {
          const errMsg = res.data.error
          if(errMsg.indexOf('이미') !== -1) this.$notify('[' + item.IDX + ']는 ' + errMsg)
          
        }
      }).catch( (err) => {
        console.log(err)
        alert('다시 시도해주세요')
      }).finally( () => {
        this.emitter.emit('dialog', {flag:false, title:'loading'})
      })
    },
    async search(param) {
      this.emitter.emit('dialog', {flag:true, title:'loading'})
      const type = param.type
      const page = param.page
      if(type === 'SELECT_NOTICE4') {
        return this.api.axiosGql(type, {type:type, noticeType: 'msg', page:page, id:this.api.getCookies('id')})
      } else {
        this.resultJson = []
        param.id = this.api.getCookies('id')
        param.page = Number(page)
        this.api.axiosGql(type, param)
        .then( async (res) => {
          if(res.status === 200) {
            const result = res.data
            if(type === 'SELECT_ORDER_LIKE') {
              this.currentSlide = []
              for(let i=0; i<result.length; i++) {
                this.currentSlide.push(0)
              }
            }
            if(res.length !== 0) this.resultJson = result
          } else alert(res.data.error)
        }).catch( (err) => {
          console.log(err)
          alert('다시 시도해주세요')
        })
      }
      this.emitter.emit('dialog', {flag:false, title:'loading'})
    },
    getNextSaturday() {
      for(var i=1; i<8; i++) {
        let date = new Date()
        let diffDate = new Date(date.setDate(date.getDate() + i, date.getDay()))
        if(diffDate.getDay() === 6) {
          const nextSaturday = new Date(diffDate.getTime() - (diffDate.getTimezoneOffset() * 60000)).toISOString()
          return new Date(nextSaturday).toISOString().slice(0,10) + ' 00:00:00' 
        }
      }
    },
    setValue(point) {
      this.point = point
    },
    // menu_click(menu_idx) {
      // if(menu_idx === 0) {
      //   this.$refs.countdown.style.visibility = ''
      // } else this.$refs.countdown.style.visibility = 'hidden'
      // this.idx = '-1'
      // for(var i=0; i<5; i++) {
      //   let menu = document.getElementById('menu' + i)
      //   let sub_menu = document.getElementById('sub_menu' + i)
      //   if(i === menu_idx) {
      //     menu.setAttribute("class", "active")
      //     sub_menu.style.display = "block"
      //   } else {
      //     menu.setAttribute("class", "")
      //     sub_menu.style.display = "none"
      //   }
      // }
      // if(menu_idx === 0) this.lottoKey += 1
    // },
    getPoint(history) {
      const type = history.TYPE
      if(type === '로그인')           return "+1"
      else if(type === '거래완료')    return "+30"
      else if(type === '로또')        return -10 * (history.VAL-1)
      else if(type === '로또미당첨')  return history.VAL
      else if(type === '로또당첨')    return "+" + Number(1000 * history.VAL).toLocaleString()
    },
    // async request() {
    //   window.Kakao.API.request({
    //     url: '/v2/user/me',
    //     success: async (response) => {
    //       let flag = false
    //       await this.api.axiosGql('SELECT_COMMON_USER', {type: 'SELECT_COMMON_USER', id:response.id })
    //       .then( async (res) => {
    //         const result = res.data
    //         if(result === 'null' || result === undefined || result === null ) flag = false
    //         else flag = true
    //       })
    //       .catch((err) => {
    //         console.log(err)
    //       })
    //       if(flag) {
    //         this.emitter.emit("setCookies", {login:true, id:response.id, nickname:response.properties.nickname, img:response.properties.thumbnail_image, mail:response.kakao_account.email})
    //         this.menu_click(0)
    //         this.reload('dashboard')
    //       } else this.showRegistModal(response.id)
    //     },
    //     fail: (error) => {
    //       console.log(error);
    //     }
    //   })
    // },
    // async login() {
    //   window.Kakao.Auth.login({
    //     success: () => {
    //       this.request()
    //     },
    //     fail: () => {
    //       this.$notify("다시 시도해주세요.")
    //     },
    //   })
    // }, 
    logout() {
      try {
        window.Kakao.Auth.logout(() => {
          const id = this.api.getCookies('id')
          this.api.axiosGql('DELETE_COMMON_USER', {type: 'DELETE_COMMON_USER', id:id })
          this.api.axiosGql('INSERT_POINT_HISTORY', {type: 'INSERT_POINT_HISTORY', id:id, point: 0, pointType:'탈퇴', val: ''})
          this.emitter.emit("setCookies", {login:false, id:'', nickname:'',img:'', mail:'', comnum:'', comname:''})
          this.tmp = 'not'
          alert('탈퇴되었습니다')
          // this.$refs.countdown.style.visibility = 'hidden'
        })
      } catch (error) {
        console.log(error)
        const id = this.api.getCookies('id')
        this.api.axiosGql('DELETE_COMMON_USER', {type: 'DELETE_COMMON_USER', id:id })
        this.api.axiosGql('INSERT_POINT_HISTORY', {type: 'INSERT_POINT_HISTORY', id:id, point: 0, pointType:'탈퇴', val: ''})
        this.emitter.emit("setCookies", {login:false, id:'', nickname:'',img:'', mail:'', comnum:'', comname:''})
        this.tmp = 'not'
        alert('탈퇴되었습니다')
        // this.$refs.countdown.style.visibility = 'hidden'
      }
    },
    getCookies(key) {
      return this.api.getCookies(key)
    },
    reload(flag) {
      if(flag === 'dashboard') {
        // this.lottoKey += 1
        // 20240907 포인트 기능 주석
        const id = this.api.getCookies('id')
        this.api.axiosGql('SELECT_MYPAGE', {type: 'SELECT_MYPAGE',id:id})
        .then( async (res) => {
          let json = res.data
          if(!(json === null || id === undefined || id === '')) {
            this.point = json[0].POINT
            this.emitter.emit("setValue", Number(json[0].POINT))
          }
        })
        .catch((err) => {
          console.log('err')
          console.log(err)
        })
        this.getPointHistory(1)
        .then( async (res) => {
          let json = res.data.data.getPointHistory
          if(json !== null) this.pointHistoryList = json
        })
        .catch((err) => {
          console.log(err)
        })
        this.$emit("setMenu", {page:'myinfo'})
      // } else {
      //   let idx = flag.idx
      //   this.menu_click(idx)
      }
      this.mypageKey++
    },
    async msgPaging(type) {
      if(type === 'prev') {
        if(this.msgPage === 1) {
          alert('첫 페이지입니다.')
          this.$notify('첫 페이지입니다.')
          return
        } else this.msgPage -= 1
      } else this.msgPage += 1
      await this.search({type:'SELECT_NOTICE4', page:this.msgPage})
      .then( async (res) => {
        let json = res.data
        if(json === undefined || json.length === 0) {
          this.msgPage -= 1
          alert('마지막 페이지입니다.')
          this.$notify('마지막 페이지입니다.')
        } else this.msgList = json
      }).catch( (err) => {
        console.log(err)
        if(type === 'prev') this.msgPage += 1
        else this.msgPage -= 1
      })
      .finally(()=> {
        this.emitter.emit('dialog', {flag:false, title:'loading'})
      })
    },
    paging(type) {
      if(type === 'prev') {
        if(this.page === 1) {
          this.$notify('첫 페이지입니다.')
          return
        } else this.page -= 1
      } else this.page += 1
      this.getPointHistory(this.page).then( async (res) => {
        let json = res.data.data.getPointHistory
        if(json.length === 0) {
          this.page -= 1
          alert('마지막 페이지입니다.')
          this.$notify('마지막 페이지입니다.')
        } else {
          this.pointHistoryList = json
        }
      }).catch( (err) => {
        console.log(err)
        if(type === 'prev') this.page += 1
        else this.page -= 1
      })
    },
    // likePaging(type) {
    //   this.collapseAll()
    //   if(type === 'prev') {
    //     if(this.likePage === 1) {
    //       alert('첫 페이지입니다.')
    //       return
    //     } else this.likePage -= 1
    //   } else this.likePage += 1
    //   this.api.axiosGql('SELECT_ORDER_LIKE', {id:this.api.getCookies('id'), page:this.likePage, type:'SELECT_ORDER_LIKE'})
    //   .then( async (res) => {
    //     let json = res.data
    //     if(json.length === 0) {
    //       this.likePage -= 1
    //       alert('마지막 페이지입니다.')
    //     } else {
    //       this.resultJson = json
    //       // scroll top
    //       const el = document.getElementById('mypageMenu0')
    //       el.scrollIntoView(el.scrollTop)
        
    //     }
    //   }).catch( (err) => {
    //     console.log(err)
    //     if(type === 'prev') this.likePage += 1
    //     else this.likePage -= 1
    //   })
    // },
    // collapse(param) {
    //   const sub_idx = param.idx
    //   const sub_menu_list = document.getElementById("mypage-order")
    //   let col_btn_list = sub_menu_list.getElementsByTagName("button")
    //   let col_con_list = sub_menu_list.getElementsByClassName("mypage-content")
    //   const btn = col_btn_list[sub_idx]
    //   const con = col_con_list[sub_idx]
      
    //   if(con === undefined) {
    //     this.collapseAll()
    //     const el = document.getElementById('mypage-order')
    //     el.scrollIntoView()
    //   } else {
    //     // open
    //     const btnClass = btn.getAttribute("class") 
    //     if(btnClass === 'mypage-collapsible select' || btnClass === 'mypage-collapsible select text-cut') {
    //       btn.setAttribute("class", "mypage-collapsible text-cut")
    //       btn.style.color = "black"
    //       btn.style.fontWeight = ""
    //       con.style.maxHeight = null
    //     } else {    // close
    //       this.collapseAll()
    //       btn.setAttribute("class", "mypage-collapsible select text-cut")
    //       btn.style.fontWeight = "bold"
    //       con.style.maxHeight = con.scrollHeight + "px"
    //     }
    //   }
    // },    
    // collapseAll() {
    //   const sub_menu_list = document.getElementById("mypage-order")
    //   if(sub_menu_list !== null && sub_menu_list !== undefined) {
    //     let col_btn_list = sub_menu_list.getElementsByTagName("button")
    //     let col_con_list = sub_menu_list.getElementsByClassName("mypage-content")
        
    //     for(var i = 0; i < col_btn_list.length; i++) {
    //       let colBtn = col_btn_list[i]
    //       if(colBtn !== undefined) {
    //         colBtn.setAttribute("class", "mypage-collapsible text-cut")
    //         colBtn.style.color = "black"
    //         colBtn.style.fontWeight = ""
    //       } 
    //       let tmpCon = col_con_list[i]
    //       if(tmpCon !== undefined ) tmpCon.style.maxHeight = null
    //     }
    //   }
    // },
    async getPointHistory(page) {
      let id = this.api.getCookies('id')
      return this.api.axiosGql('SELECT_POINT_HISTORY', {id:id, page:page, type:'SELECT_POINT_HISTORY'})
    },
    clearCartInfo() {
      this.totalWonPrice = 0,
      this.totalForeignPrice = 0.0,
      this.totalForeignDeliveryPrice = 0.0
      this.selectedCountry = ''
    }
  }
}
</script>

<style scoped>
.point {
  font-weight: normal;
  margin-left: 10px;
}
/* .button {
  float:right;
  background-color: lightsalmon;  
  color: white;
  border-radius: 9px;
  border: 0px;
  padding:5px;
  max-width: 50px;
  cursor: pointer;
  margin-right:5px;
} */
#sub_menu1, #sub_menu2, #sub_menu3, #sub_menu4 {
  display: none;
}
.mypage-table td:nth-child(even){
  text-align: center;
  width:20%;
  
  border: 1px dotted #ffa07a; 
}
.mypage-table td:nth-child(odd){
  text-align: center;
  width:20%;
  border: 1px dotted #ffa07a; 
}
.mypage-table tr {
  height:30px;
}
.mypage-table td:nth-child(odd) {
  
  border: 1px dotted #ffa07a; 
}
.mypage-table tr:nth-child(odd) td{
  text-align: center;
  border: 1px dotted #ffa07a; 
}
table {
  border-collapse: collapse;
}
.mypage-table tr:first-child td {
  border-top: 0;
}
.mypage-table tr td:first-child {
  border-left: 0;
}
.mypage-table tr:last-child td {
  border-bottom: 0;
}
.mypage-table tr td:last-child {
  border-right: 0;
}
#shop-modal-table {
  border-collapse: collapse;
  border-spacing: 0;
}
#shop-modal-table > tr {
  height : 50px;
}
#shop-modal-table > tr > td:nth-child(odd) {
  width:150px;
  font-weight: bolder;
  background: aliceblue;
}
.button_mod {
  background-color: lightsalmon;  
  color: white;
  padding: 5px;
  font-weight: bold;
  border-radius: 9px;
  border: 0px;
  min-width: 100px;
  cursor: pointer;
  margin-top:10px;
}
.navbar {
  width: 100%;
  background-color: white;
  overflow: auto;
  cursor: pointer;
  background-color: lightgoldenrodyellow;
  border-radius: 9px;
}
.navbar span {
  padding-top: 5px;
  padding-bottom: 5px;
  float: left;
  color: black;
  text-align: center;
  border-radius: 9px;
}
.navbar span.active {
  background-color: lightsalmon;
  color: white;
  font-weight: bold;
}
.mypage-collapsible:after {
  content: '\002B';
  font-weight: bold;
  float: left;
  color: red;
  /* margin-left: 5px;
  margin-right: 5px; */
  position:absolute;
}
.select:after {
  content: "\2212";
}
.mypage-collapsible {
  background-color: lightgoldenrodyellow;
  color: black;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 15px;
  border-radius: 9px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
}
.mypage-content {
  max-height: 0;
  height: auto;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.shop-modal_button {
  background-color: lightsalmon;  
  color: white;
  padding: 6px;
  margin:10px;
  font-weight: bold;
  border-radius: 9px;
  border: 0px;
  cursor: pointer;
  min-width:50px;
}
.cart_table {
  width:100%;
}
.cart-regist {
  width: 100%;
  margin-top: 25px;
  background-color: lightsalmon;  
  color: white;
  padding: 10px;
  font-weight: bold;
  border-radius: 9px;
  border: 0px;
  min-width: 100px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>